import { useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Tooltip } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import "./storeTable.css";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import translations from "../../../locals/translation";

export default function DataGridDemo({
  banner,
  offers,
  setOpen2,
  setOpen,
  setSelecedProDuctId,
  deleteProduct,
}) {
  const language = useSelector((state) => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };
  const navigate = useNavigate();
  const [offersToMap, setOffersToMap] = React.useState({});
  const [rows, setRows] = React.useState([]);

  // Columns configuration
  const columns = React.useMemo(
    () => [
      {
        field: "productdetails",
        headerName: t("Productos"),
        width: 600,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Avatar
              alt={params.row.name}
              src={
                params.row.product_images?.[0]?.imageUrl || "fallback-image-url"
              }
            />
            <div>
              <p style={{ margin: 0, fontWeight: "bold" }}>{params.row.name}</p>
            </div>
          </div>
        ),
      },
      {
        field: "dates",
        headerName: t("Fechas"),
        width: 350,
        editable: true,
      },
      {
        field: "price",
        headerName: t("Precio"),
        type: "number",
        width: 300,
        editable: true,
      },
      {
        field: "offers",
        headerName: t("Ofertas"),
        type: "number",
        width: 432,
        editable: false,
        renderHeader: () => (
          <div
            onClick={() => setOpen(true)}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <BiPlus />
          </div>
        ),
        renderCell: (params) => (
          <div className="rendercell-offers">
            <div
              onClick={() => {
                setOpen2(true);
                setSelecedProDuctId(params.row.id);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                marginRight: "10px",
                height: "2rem",
                width: "2rem",
                border: "1px solid white",
                borderRadius: "50%",
                padding: "2px",
              }}
            >
              <BiPlus />
            </div>
            {params.row.offers?.length > 0 ? (
              params.row.offers.map((item, i) => (
                <span key={`${params.row.id}-${i}`}>
                  {i === 0 || ", "}
                  {item}
                </span>
              ))
            ) : (
              <span>{t("Sin ofertas")}</span>
            )}
          </div>
        ),
      },
      {
        field: "actions",
        headerName: t("Acción"),
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title={t("En mantenimiento")} placement="top">
              {/* <FiEdit
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/editproduct2", {
                    state: params.row.actions,
                  })
                }
              /> */}
            </Tooltip>
            <Tooltip title={t("Eliminar el producto")} placement="top">
              {console.log("params: ", params)}
              <MdDeleteOutline
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => deleteProduct(params.row.id)}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [setOpen, setOpen2, setSelecedProDuctId, deleteProduct, navigate]
  );

  // Process offers and map them to products
  React.useEffect(() => {
    if (!offers) return;
    const result = {};

    offers.forEach((item) => {
      const productId = item?.product_id;
      const discountPercentage = item?.offer?.discount_percentage;

      if (!result[productId]) {
        result[productId] = { offersPerc: [] };
      }
      if (!result[productId]?.offersPerc.includes(discountPercentage)) {
        result[productId]?.offersPerc.push(discountPercentage);
      }
    });

    setOffersToMap(result);
  }, [offers]);

  // Create rows for the DataGrid
  React.useEffect(() => {
    if (!banner || !offersToMap) return;

    const updatedRows = banner.map((item) => {
      const productOffers =
        offersToMap[String(item.product_id)]?.offersPerc || [];
      return {
        id: item.product_id,
        name: item.name,
        description: item.description,
        product_images: item.product_images,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        productdetails: "",
        dates: `${t("Creado")}: ${new Date(
          item.createdAt
        ).toLocaleDateString()} | ${t("Actualizado")}: ${new Date(
          item.updatedAt
        ).toLocaleDateString()}`,
        price: `${t("inicial")} ${item.initial_price} / ${t("Con descuento")} ${
          item.discounted_price
        }`,
        offers: productOffers,
        actions: item,
      };
    });

    setRows(updatedRows);
  }, [banner, offersToMap]);

  return (
    <Box
      sx={{ height: 380, width: "100%", minWidth: "500px", overflowX: "auto" }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        className="store-table-custom"
        sx={{
          background: "white",
          color: "black",
          width: "100%",
          minWidth: "500px",
          overflowX: "auto",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        onCellClick={(params, event) => {
          const lastColumnField = columns[columns.length - 1]?.field;
          const secondLastField = columns[columns.length - 2]?.field;
          if (
            params.field === lastColumnField ||
            params.field === secondLastField
          ) {
            event.stopPropagation();
          } else {
            navigate("/productdetails", { state: params.row.actions });
          }
        }}
      />
    </Box>
  );
}
